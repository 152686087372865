export const keys = ['Ab', 'A', 'Bb', 'B', 'C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G']

export const harmonicEnvironments = [
  { halfSteps: 0, label: '1st', mode: 'Major / Ionian' },
  { halfSteps: 2, label: '2nd', mode: 'Dorian' },
  { halfSteps: 4, label: '3rd', mode: 'Phrygian' },
  { halfSteps: 5, label: '4th', mode: 'Lydian' },
  { halfSteps: 7, label: '5th', mode: 'Mixolydian' },
  { halfSteps: 9, label: '6th', mode: 'Minor / Aeolian' },
  { halfSteps: 11, label: '7th', mode: 'Locrian' },
]

export const allGenres = [
  { label: 'Meditative' },
  { label: 'Modern' },
  { label: 'Jazz' }
]

export const match1 = /(.*)(A|Ab|B|Bb|C|Db|D|Eb|E|F|Gb|G)\)?\.mp3/