export const tracks = {
  0: {
    0: [
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_meditative_key_of_Ab.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_meditative_key_of_A.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_meditative_key_of_Bb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_meditative_key_of_B.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_meditative_key_of_C.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_meditative_key_of_Db.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_meditative_key_of_D.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_meditative_key_of_Eb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_meditative_key_of_E.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_meditative_key_of_F.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_meditative_key_of_Gb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_meditative_key_of_G.mp3",
    ],
    1: [
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_modern_key_of_Ab.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_modern_key_of_A.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_modern_key_of_Bb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_modern_key_of_B.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_modern_key_of_C.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_modern_key_of_Db.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_modern_key_of_D.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_modern_key_of_Eb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_modern_key_of_E.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_modern_key_of_F.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_modern_key_of_Gb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_modern_key_of_G.mp3",
    ],
    2: [
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_jazz_key_of_Ab.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_jazz_key_of_A.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_jazz_key_of_Bb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_jazz_key_of_B.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_jazz_key_of_C.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_jazz_key_of_Db.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_jazz_key_of_D.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_jazz_key_of_Eb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_jazz_key_of_E.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_jazz_key_of_F.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_jazz_key_of_Gb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_1_jazz_key_of_G.mp3",
    ]
  },
  1: {
    0: [
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_meditative_key_of_Ab.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_meditative_key_of_A.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_meditative_key_of_Bb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_meditative_key_of_B.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_meditative_key_of_C.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_meditative_key_of_Db.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_meditative_key_of_D.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_meditative_key_of_Eb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_meditative_key_of_E.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_meditative_key_of_F.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_meditative_key_of_Gb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_meditative_key_of_G.mp3",],
    1: [
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_modern_key_of_Ab.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_modern_key_of_A.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_modern_key_of_Bb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_modern_key_of_B.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_modern_key_of_C.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_modern_key_of_Db.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_modern_key_of_D.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_modern_key_of_Eb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_modern_key_of_E.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_modern_key_of_F.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_modern_key_of_Gb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_modern_key_of_G.mp3",],
    2: [
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_jazz_key_of_Ab.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_jazz_key_of_A.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_jazz_key_of_Bb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_jazz_key_of_B.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_jazz_key_of_C.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_jazz_key_of_Db.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_jazz_key_of_D.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_jazz_key_of_Eb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_jazz_key_of_E.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_jazz_key_of_F.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_jazz_key_of_Gb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_2_jazz_key_of_G.mp3",
    ]
  },
  2: {
    0: [
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_meditative_key_of_Ab.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_meditative_key_of_A.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_meditative_key_of_Bb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_meditative_key_of_B.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_meditative_key_of_C.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_meditative_key_of_Db.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_meditative_key_of_D.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_meditative_key_of_Eb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_meditative_key_of_E.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_meditative_key_of_F.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_meditative_key_of_Gb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_meditative_key_of_G.mp3",
    ],
    1: [
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_modern_key_of_Ab.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_modern_key_of_A.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_modern_key_of_Bb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_modern_key_of_B.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_modern_key_of_C.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_modern_key_of_Db.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_modern_key_of_D.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_modern_key_of_Eb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_modern_key_of_E.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_modern_key_of_F.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_modern_key_of_Gb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_modern_key_of_G.mp3",
    ],
    2: [
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_jazz_key_of_Ab.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_jazz_key_of_A.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_jazz_key_of_Bb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_jazz_key_of_B.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_jazz_key_of_C.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_jazz_key_of_Db.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_jazz_key_of_D.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_jazz_key_of_Eb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_jazz_key_of_E.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_jazz_key_of_F.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_jazz_key_of_Gb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_3_jazz_key_of_G.mp3",
    ]
  },
  3: {
    0: [
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_meditative_key_of_Ab.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_meditative_key_of_A.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_meditative_key_of_Bb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_meditative_key_of_B.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_meditative_key_of_C.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_meditative_key_of_Db.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_meditative_key_of_D.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_meditative_key_of_Eb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_meditative_key_of_E.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_meditative_key_of_F.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_meditative_key_of_Gb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_meditative_key_of_G.mp3",
    ],
    1: [
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_modern_key_of_Ab.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_modern_key_of_A.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_modern_key_of_Bb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_modern_key_of_B.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_modern_key_of_C.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_modern_key_of_Db.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_modern_key_of_D.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_modern_key_of_Eb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_modern_key_of_E.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_modern_key_of_F.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_modern_key_of_Gb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_modern_key_of_G.mp3",
    ],
    2: [
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_jazz_key_of_Ab.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_jazz_key_of_A.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_jazz_key_of_Bb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_jazz_key_of_B.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_jazz_key_of_C.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_jazz_key_of_Db.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_jazz_key_of_D.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_jazz_key_of_Eb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_jazz_key_of_E.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_jazz_key_of_F.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_jazz_key_of_Gb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_4_jazz_key_of_G.mp3",
    ]
  },
  4: {
    0: [
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_meditative_key_of_Ab.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_meditative_key_of_A.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_meditative_key_of_Bb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_meditative_key_of_B.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_meditative_key_of_C.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_meditative_key_of_Db.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_meditative_key_of_D.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_meditative_key_of_Eb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_meditative_key_of_E.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_meditative_key_of_F.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_meditative_key_of_Gb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_meditative_key_of_G.mp3",
    ],
    1: [
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_modern_key_of_Ab.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_modern_key_of_A.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_modern_key_of_Bb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_modern_key_of_B.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_modern_key_of_C.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_modern_key_of_Db.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_modern_key_of_D.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_modern_key_of_Eb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_modern_key_of_E.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_modern_key_of_F.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_modern_key_of_Gb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_modern_key_of_G.mp3",
    ],
    2: [
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_jazz_key_of_Ab.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_jazz_key_of_A.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_jazz_key_of_Bb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_jazz_key_of_B.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_jazz_key_of_C.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_jazz_key_of_Db.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_jazz_key_of_D.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_jazz_key_of_Eb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_jazz_key_of_E.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_jazz_key_of_F.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_jazz_key_of_Gb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_5_jazz_key_of_G.mp3",
    ]
  },
  5: {
    0: [
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_meditative_key_of_Ab.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_meditative_key_of_A.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_meditative_key_of_Bb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_meditative_key_of_B.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_meditative_key_of_C.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_meditative_key_of_Db.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_meditative_key_of_D.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_meditative_key_of_Eb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_meditative_key_of_E.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_meditative_key_of_F.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_meditative_key_of_Gb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_meditative_key_of_G.mp3",
    ],
    1: [
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_modern_key_of_Ab.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_modern_key_of_A.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_modern_key_of_Bb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_modern_key_of_B.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_modern_key_of_C.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_modern_key_of_Db.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_modern_key_of_D.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_modern_key_of_Eb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_modern_key_of_E.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_modern_key_of_F.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_modern_key_of_Gb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_modern_key_of_G.mp3",
    ],
    2: [
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_jazz_key_of_Ab.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_jazz_key_of_A.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_jazz_key_of_Bb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_jazz_key_of_B.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_jazz_key_of_C.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_jazz_key_of_Db.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_jazz_key_of_D.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_jazz_key_of_Eb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_jazz_key_of_E.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_jazz_key_of_F.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_jazz_key_of_Gb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_6_jazz_key_of_G.mp3",
    ]
  },
  6: {
    0: [
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_meditative_key_of_Ab.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_meditative_key_of_A.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_meditative_key_of_Bb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_meditative_key_of_B.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_meditative_key_of_C.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_meditative_key_of_Db.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_meditative_key_of_D.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_meditative_key_of_Eb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_meditative_key_of_E.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_meditative_key_of_F.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_meditative_key_of_Gb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_meditative_key_of_G.mp3",
    ],
    1: [
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_modern_key_of_Ab.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_modern_key_of_A.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_modern_key_of_Bb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_modern_key_of_B.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_modern_key_of_C.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_modern_key_of_Db.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_modern_key_of_D.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_modern_key_of_Eb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_modern_key_of_E.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_modern_key_of_F.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_modern_key_of_Gb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_modern_key_of_G.mp3",
    ],
    2: [
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_jazz_key_of_Ab.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_jazz_key_of_A.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_jazz_key_of_Bb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_jazz_key_of_B.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_jazz_key_of_C.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_jazz_key_of_Db.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_jazz_key_of_D.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_jazz_key_of_Eb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_jazz_key_of_E.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_jazz_key_of_F.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_jazz_key_of_Gb.mp3",
      "https://d3rl7arpgnbsx6.cloudfront.net/j1_7w_mp3/IFR_Seven_Worlds_7_jazz_key_of_G.mp3",
    ]
  },
};
