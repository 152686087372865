import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"

const EnvironmentCheckbox = ({
  environments,
  environment,
  label,
  onChange,
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          size="sm"
          sx={{ color: "success" }}
          checked={environments.includes(environment)}
          onChange={(event) => onChange(environment, event.target.checked)}
        />
      }
      label={label}
    />
  )
}

export default EnvironmentCheckbox
