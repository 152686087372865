import "./App.css"

import { useState } from "react"

import {
  Alert,
  Button,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Typography,
} from "@mui/material"

import EnvironmentCheckbox from "./components/EnvironmentCheckbox"
import Footer from "./components/Footer"
import Header from "./components/Header"

import { keys, harmonicEnvironments, allGenres, match1 } from "./lib/constants"
import { getRandomInt } from "./lib/utils"

import { tracks } from "./tracks"

const getTrack = (environment, genre) => {
  return getRandomInt(tracks[environment - 1][genre].length)
}

const getKey = (environment, key) => {
  const keyIndex = keys.indexOf(key) || 0
  const { halfSteps } = harmonicEnvironments[environment - 1]
  return keys[(keyIndex + halfSteps) % 12]
}

function App() {
  const [environments, setEnvironments] = useState([1])
  const [genres, setGenres] = useState([0, 1, 2])
  const [show, setShow] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const track = 0
  const src = tracks[0][0][track]
  const [answer, setAnswer] = useState({ environment: 1, track, src })

  const { environment, src: answerSrc } = answer
  const matches = (answerSrc || "").match(match1)
  const rootNote = matches ? getKey(environment, matches[2]) : undefined

  const getEnvironment = () => {
    return environments.length
      ? environments[getRandomInt(environments.length)]
      : 1
  }

  const getGenre = () => {
    return genres.length ? genres[getRandomInt(genres.length)] : 0
  }

  const updateEnvironments = (environment, checked) => {
    const currentEnvironments = environments
    if (checked) {
      setEnvironments([...currentEnvironments, environment].sort())
    } else {
      setEnvironments(currentEnvironments.filter((env) => env !== environment))
    }
  }

  const updateGenres = (genre, checked) => {
    const currentGenres = genres
    if (checked) {
      setGenres([...currentGenres, genre].sort())
    } else {
      setGenres(currentGenres.filter((g) => g !== genre))
    }
  }

  const pickNewTrack = () => {
    const environment = getEnvironment()
    const genre = getGenre()
    const track = getTrack(environment, genre)
    // chrome overwrites the audio #id on replace
    // const player = document.getElementById("audio-player")
    // Workaround
    const players = document.getElementsByTagName("audio")
    const player = players.length ? players[0] : undefined
    if (player) {
      const src = tracks[environment - 1][genre][track]
      player.src = src
      // console.log({ environment, genre, track, src })
      player.play()
      setAnswer({ environment, track, src })
    } else {
      setOpenSnackbar(true)
    }
  }

  const playEnded = () => {
    pickNewTrack()
  }

  const handleClose = () => {
    setOpenSnackbar(false)
  }

  return (
    <div className="App">
      <Header />
      <body className="app-body">
        <Typography variant="h6" sx={{ mb: 2, mt: 2, fontWeight: 600 }}>
          As you listen, try to orient yourself to find the root note and scale
          <span className="trim"> of the music</span>
        </Typography>
        <Typography variant="subtitle">
          <strong>Choose one or more Seven Worlds Harmonic Environments</strong>
        </Typography>

        <div className="container">
          <div className="checkboxes">
            <FormGroup row={true}>
              {harmonicEnvironments.map((he, index) => (
                <EnvironmentCheckbox
                  key={`he-${index}`}
                  environments={environments}
                  environment={index + 1}
                  label={he.label}
                  onChange={updateEnvironments}
                />
              ))}
            </FormGroup>
          </div>
          <Typography variant="subtitle">
            <strong>And one or more Genres</strong>
          </Typography>
          <div className="checkboxes">
            <FormGroup row={true}>
              {allGenres.map((g, index) => (
                <EnvironmentCheckbox
                  key={`g-${index}`}
                  environments={genres}
                  environment={index}
                  label={g.label}
                  onChange={updateGenres}
                />
              ))}
            </FormGroup>
          </div>
          <Button
            className="shuffle"
            variant="contained"
            onClick={pickNewTrack}
            sx={{ backgroundColor: "#5cb85c" }}
          >
            Shuffle
          </Button>
          <audio
            id="audio-player"
            className="player"
            controls="controls"
            src={src}
            type="audio/mp3"
            onEnded={playEnded}
          >
            play me
          </audio>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              color="error"
              variant="filled"
            >
              <Typography>Unable to locate Audio Player</Typography>
            </Alert>
          </Snackbar>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={show}
                  onChange={(event) => setShow(event.target.checked)}
                />
              }
              label="Show Answer"
            />
          </FormGroup>
          {show && environment && (
            <div className="answer">
              <div className="answer-item">{`${
                harmonicEnvironments[environment - 1].label
              } Harmonic Environment`}</div>
              <div className="answer-item">{`Note ${environment}: ${rootNote}`}</div>
              <div>{`Key: ${rootNote} ${
                harmonicEnvironments[environment - 1].mode
              }`}</div>
            </div>
          )}
        </div>
      </body>
      <Footer />
    </div>
  )
}

export default App
