import "./Footer.css"

const Footer = () => (
  <div className="footer">
    <div className="copyrights">
      &copy;{" "}
      <a
        href="mailto:michael@delayedkarma.com?subject=Orient in the Key"
        target="_blank"
        rel="noreferrer"
      >
        Delayed Karma, LLC
      </a>
      , 2022 &ndash; Jam Tracks &copy;{" "}
      <a
        href="https://improviseforreal.com/products/ifr-jam-tracks-level-1-seven-worlds"
        target="_blank"
        rel="noreferrer"
      >
        Improvise For Real
      </a>
    </div>
    <p className="disclaimer">
      <small>
        Disclaimer: This application is intended for students who have already
        purchased IFR Jam Tracks Level 1: Seven Worlds
      </small>
    </p>
  </div>
)

export default Footer
